import request from '@/utils/request'

export function getOrderNum(data) {
  return request({
    url: 'order-web-api/supply/order/queryServiceCountLists',
    method: 'POST',
    data,
  })
} // 获取统计服务数量
export function getOrderQuery(data) {
  return request({
    url: 'order-web-api/supply/order/getServiceList',
    method: 'POST',
    data,
  })
} // 获取订单分页列表
export function getOrderDetail(data) {
  return request({
    url: 'order-web-api/supply/order/getServiceDetail',
    method: 'POST',
    data,
  })
} // 获取订单详情
export function getDetailAddOrderList(data) {
  return request({
    url: 'order-web-api/supply/order/getOrderLists',
    method: 'POST',
    data,
  })
} // 获取加购订单列表
export function getDetailServerRecord(data) {
  return request({
    url: 'order-web-api/common/service/record/getListBox',
    method: 'POST',
    data,
  })
} // 获取订单详情服务记录
export function getDetailServerProtocol(data) {
  return request({
    url: 'order-web-api/supply/order/getOrderTreatyRecod',
    method: 'POST',
    data,
  })
}
export function getDetailPhotos(data) {
  return request({
    url: 'order-web-api/supply/order/getServiceUserPhotos',
    method: 'POST',
    data,
  })
} // 获取订单详情照片库
export function getDetailPhone(data) {
  return request({
    url: 'order-web-api/supply/order/getPortDelPhone',
    method: 'POST',
    data,
  })
} // 获取订单详情照片库 联系电话
export function getDetailEvaluation(data) {
  return request({
    url: 'evaluation-web-api/supply/evaluation/getEvaluationInfoByServiceId',
    method: 'POST',
    data,
  })
} // 获取订单评价详情
export function evaluateReply(data) {
  return request({
    url: 'evaluation-web-api/supply/evaluation/saveEvaluationReply',
    method: 'POST',
    data,
  })
} // 评价回复

export function getAddProductList(data) {
  return request({
    url: 'product-web-api/supplier/product/findBaseAdditionList',
    method: 'POST',
    data,
  })
} // 获取加购产品列表
export function getAddProductTypeList(data) {
  return request({
    url: 'product-web-api/supplier/productCategory/list',
    method: 'POST',
    data,
  })
} // 获取加购产品列表
export function addProduct(data) {
  return request({
    url: 'order-web-api/supply/order/addition',
    method: 'POST',
    data,
  })
} // 加购产品

export function getOrderRecordList(data) {
  return request({
    url: 'order-web-api/supply/order/getOrderRecordList',
    method: 'POST',
    data,
  })
} // 获取加购订单详情订单记录
export function getOrderRecordDetail(data) {
  return request({
    url: 'order-web-api/supply/order/getOrderDetail',
    method: 'POST',
    data,
  })
} // 获取加购订单详情
export function getOrderRecordRefundDetail(data) {
  return request({
    url: 'order-web-api/supply/order/queryOrderRefund',
    method: 'POST',
    data,
  })
} // 获取加购订单详情售后记录详情

export function getOrderPayLink(data) {
  return request({
    url: 'order-web-api/supply/order/getOrderPayListByOrderId',
    method: 'POST',
    data,
  })
} // 获取订单付款链接

export function enterOrder(data) {
  return request({
    url: 'order-web-api/supply/order/confirmOrder',
    method: 'POST',
    data,
  })
} // 确认接单
export function refuseOrder(data) {
  return request({
    url: 'order-web-api/supply/order/refuseOrder',
    method: 'POST',
    data,
  })
} // 拒绝接单
export function enterStore(data) {
  return request({
    url: 'order-web-api/supply/order/confirmStore',
    method: 'POST',
    data,
  })
} // 确认到店
export function enterFinishServer(data) {
  return request({
    url: 'order-web-api/supply/order/confirmFinish',
    method: 'POST',
    data,
  })
} // 确认完成服务
export function uploadImg(data) {
  return request({
    url: 'order-web-api/supply/order/uploadPic',
    method: 'POST',
    data,
  })
} // 上传照片
export function cancelOrder(data) {
  return request({
    url: 'order-web-api/supply/order/cancel',
    method: 'POST',
    data,
  })
} // 取消订单

export function verPcProduct(data) {
  return request({
    url: 'order-web-api/supply/order/isCanConfirm',
    method: 'POST',
    data,
  })
} // 验证是否存在售后产品
export function getServeSuccessCode(data) {
  return request({
    url: 'order-web-api/supply/order/getServiceEndWriteNo',
    method: 'POST',
    data,
  })
} // 获取服务完成验证码

// 移动端
export function verProduct(data) {
  return request({
    url: 'order-web-api/api/order/isCanConfirm',
    method: 'POST',
    data,
  })
} // 验证是否存在售后产品
export function successServerOrder(data) {
  return request({
    url: 'order-web-api/api/order/confirmBndFinish',
    method: 'POST',
    data,
  })
} // 完成服务订单(H5)
export function getAfterProductInfo(data) {
  return request({
    url: 'order-web-api/api/order/getServiceOutDetails',
    method: 'POST',
    data,
  })
} // 获取售后产品信息(H5)

export function getServicebgWriteNo(data) {
  return request({
    url: 'order-web-api/supply/order/getServicebgWriteNo',
    method: 'POST',
    data,
  })
}

export function storeGetList(data) {
  return request({
    url: 'order-web-api/supply/service/store/getList',
    method: 'POST',
    data,
  })
}
export function serviceitemsDispatch(data) {
  return request({
    url: 'order-web-api/supply/service/store/dispatch',
    method: 'POST',
    data,
  })
}
export function serviceitemsDetail(data) {
  return request({
    url: 'order-web-api/supply/service/store/detail',
    method: 'POST',
    data,
  })
}
export function serviceitemsUpload(data) {
  return request({
    url: 'order-web-api/supply/service/store/upload',
    method: 'POST',
    data,
  })
}
export function getUserListPost(data) {
  return request({
    url: 'order-web-api/supply/service/store/getUserListPost',
    method: 'POST',
    data,
  })
}
export function getServiceItemsListByServiceId(data) {
  return request({
    url: 'order-web-api/supply/service/store/getServiceItemsListByServiceId',
    method: 'POST',
    data,
  })
}
export function batchDispatch(data) {
  return request({
    url: 'order-web-api/supply/service/store/batchDispatch',
    method: 'POST',
    data,
  })
}
export function sendFirstRepair(data) {
  return request({
    url: 'order-web-api/supply/service/store/sendFirstRepair',
    method: 'POST',
    data,
  })
}

export function sendViewSample(data) {
  return request({
    url: 'order-web-api/supply/service/store/sendViewSample',
    method: 'POST',
    data,
  })
}
export function sendRefinedRepair(data) {
  return request({
    url: 'order-web-api/supply/service/store/sendRefinedRepair',
    method: 'POST',
    data,
  })
}
export function sendMake(data) {
  return request({
    url: 'order-web-api/supply/service/store/sendMake',
    method: 'POST',
    data,
  })
}
export function notifyRefinedRepair(data) {
  return request({
    url: 'order-web-api/supply/service/store/notifyRefinedRepair',
    method: 'POST',
    data,
  })
}
export function takeOver(data) {
  return request({
    url: 'order-web-api/supply/service/store/takeOver',
    method: 'POST',
    data,
  })
}
export function getGenServiceItemsProductList(data) {
  return request({
    url: 'order-web-api/supply/service/store/getGenServiceItemsProductList',
    method: 'POST',
    data,
  })
}
export function batchDispatchByProduct(data) {
  return request({
    url: 'order-web-api/supply/service/store/batchDispatchByProduct',
    method: 'POST',
    data,
  })
}

export function notifyLayout(data) {
  return request({
    url: 'order-web-api/supply/service/store/notifyLayout',
    method: 'POST',
    data,
  })
}
export function confirmUpload(data) {
  return request({
    url: 'order-web-api/supply/service/store/confirmUpload',
    method: 'POST',
    data,
  })
}

export function confirmViewSample(data) {
  return request({
    url: 'order-web-api/supply/service/store/confirmViewSample',
    method: 'POST',
    data,
  })
}

export function notifyViewSample(data) {
  return request({
    url: 'order-web-api/supply/service/store/notifyViewSample',
    method: 'POST',
    data,
  })
}
export function sendSample(data) {
  return request({
    url: 'order-web-api/supply/service/store/sendSample',
    method: 'POST',
    data,
  })
}
export function dispatch(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/dispatch',
    method: 'POST',
    data,
  })
}

export function notifyAccept(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/notifyAccept',
    method: 'POST',
    data,
  })
}

export function accept(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/accept',
    method: 'POST',
    data,
  })
}

export function refuse(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/refuse',
    method: 'POST',
    data,
  })
}
export function getLogList(data) {
  return request({
    url: 'order-web-api/supply/service/store/getLogList',
    method: 'POST',
    data,
  })
}

export function checkAdditionState(data) {
  return request({
    url: 'order-web-api/supply/service/store/checkAdditionState',
    method: 'POST',
    data,
  })
}
export function start(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/start',
    method: 'POST',
    data,
  })
}

export function finish(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/finish',
    method: 'POST',
    data,
  })
}

export function getCanAdditionBaseProductTypes(data) {
  return request({
    url: 'order-web-api/supply/service/store/getCanAdditionBaseProductTypes',
    method: 'POST',
    data,
  })
}

export function getSelectListByCondition(data) {
  return request({
    url: 'order-web-api/common/service/record/getSelectListByCondition',
    method: 'POST',
    data,
  })
}

export function getSelectStatList(data) {
  return request({
    url: 'order-web-api/common/service/record/getSelectStatList',
    method: 'POST',
    data,
  })
}

export function deleteBathUserPhoto(data) {
  return request({
    url: 'order-web-api/supply/order/deleteBathUserPhoto',
    method: 'POST',
    data,
  })
}
export function findShowBaseProductTypesByOrderNo(data) {
  return request({
    url: '/image-web-api/supplier/image/files/findShowBaseProductTypesByOrderNo',
    method: 'POST',
    data,
  })
}

export function findFileListByOrderNo(data) {
  return request({
    url: 'image-web-api/supplier/image/files/findFileListByOrderNo',
    method: 'POST',
    data,
  })
}
export function confirmFinishMake(data) {
  return request({
    url: 'order-web-api/supply/service/store/confirmFinishMake',
    method: 'POST',
    data,
  })
}
export function syncPhoto(data) {
  return request({
    url: '/order-web-api/supply/order/syncPhoto',
    method: 'POST',
    data,
  })
}
export function deleteFileByIds(data) {
  return request({
    url: 'image-web-api/admin/image/files/deleteFileByIds',
    method: 'POST',
    data,
  })
}
export function getOrderItemEvaluation(data) {
  return request({
    url: 'evaluation-web-api/supply/evaluation/getOrderItemEvaluation',
    method: 'POST',
    data,
  })
}

export function getSelectParentDetail(data) {
  return request({
    url: 'order-web-api/supply/order/getSelectParentDetail',
    method: 'POST',
    data,
  })
}

export function getSelectListByConditionPhotos(data) {
  return request({
    url: 'order-web-api/supply/service/user/photos/select/getSelectListByCondition',
    method: 'POST',
    data,
  })
}
export function getServiceListCount(data) {
  return request({
    url: 'order-web-api/supply/order/getServiceListCount',
    method: 'POST',
    data,
  })
}
export function findListByRoleIds(data) {
  return request({
    url: 'user-web-api/supplier/role/findListByRoleIds',
    method: 'POST',
    data,
  })
}

export function offlinePayment(data) {
  return request({
    url: 'order-web-api/supply/order/offlinePayment',
    method: 'POST',
    data,
  })
}

export function getWaitSubscribe(data) {
  return request({
    url: 'order-web-api/supply/order/getPendingServiceList',
    method: 'POST',
    data,
  })
}

export function getServiceById(data) {
  return request({
    url: 'order-web-api/admin/orderservice/getServiceById',
    method: 'POST',
    data,
  })
}

export function getViewSampleProductList(data) {
  return request({
    url: 'order-web-api/supply/service/user/photos/select/getViewSampleProductList',
    method: 'POST',
    data,
  })
}

export function getViewFileList(data) {
  return request({
    url: 'order-web-api/supply/service/user/photos/select/getViewFileList',
    method: 'POST',
    data,
  })
}

export function getViewLayoutList(data) {
  return request({
    url: 'order-web-api/supply/service/user/photos/select/getViewLayoutList',
    method: 'POST',
    data,
  })
}

export function getViewPhotosSelectList(data) {
  return request({
    url: 'order-web-api/supply/service/user/photos/select/getViewPhotosSelectList',
    method: 'POST',
    data,
  })
}

export function shootPlan(data) {
  return request({
    url: 'order-web-api/supply/service/store/shootPlan',
    method: 'POST',
    data,
  })
}

export function storeGetServiceById(data) {
  return request({
    url: 'order-web-api/supply/service/store/getServiceById',
    method: 'POST',
    data,
  })
}

export function getOrderServiceOffDetail(data) {
  return request({
    url: '/order-web-api/user/order/getOrderServiceOffDetail',
    method: 'POST',
    data,
  })
}

export function getStoreUserList(data) {
  return request({
    url: '/supplier-web-api/api/store/user/pageList',
    method: 'POST',
    data,
  })
}

export function setServiceManager(data) {
  return request({
    url: '/order-web-api/admin/orderservice/setServiceManager',
    method: 'POST',
    data,
  })
}

export function findDetailByServiceId(data) {
  return request({
    url: '/marketing-web-api/supplier/photo/regist/findDetailByServiceId',
    method: 'POST',
    data,
  })
}

export function shootRegSave(data) {
  return request({
    url: '/marketing-web-api/supplier/photo/regist/save',
    method: 'POST',
    data,
  })
}

export function supplyIndexStoreUserPerformance(data) {
  return request({
    url: '/order-web-api/supply/order/supplyIndexStoreUserPerformance',
    method: 'POST',
    data,
  })
}

export function performanceExport(data) {
  return request({
    url: '/order-web-api/supply/order/performanceExport',
    method: 'POST',
    responseType: 'blob',
    data,
  })
}

export function confirmStore(data) {
  return request({
    url: '/order-web-api/server/order/confirmStore',
    method: 'post',
    data,
  })
}
export function deleteByItemId(data) {
  return request({
    url: '/order-web-api/supplier/service/serviceitems/deleteByItemId',
    method: 'post',
    data,
  })
}

export function orderStartServe(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/start',
    method: 'post',
    data,
    resultCatch: true,
  })
}

// 服务单评价分享
export function shareServiceEva(data) {
  return request({
    url: 'order-web-api/supplier/order/share/shareServiceEva',
    method: 'post',
    data,
    resultCatch: true,
  })
}

// 服务单关联分组
export function relevanceGroup(data) {
  return request({
    url: 'order-web-api/supply/service/store/relevanceGroup',
    method: 'post',
    data,
    resultCatch: true,
  })
}
export function getStoreUserSalePerformancePage(data) {
  return request({
    url: 'order-web-api/supply/order/getStoreUserSalePerformancePage',
    method: 'post',
    data,
  })
}
export function storeUserSalePerformanceExport(data) {
  return request({
    url: 'order-web-api/supply/order/storeUserSalePerformanceExport',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
export function getServiceInventory(data) {
  return request({
    url: 'order-web-api/admin/service/inventory/getJoinList',
    method: 'post',
    data,
  })
}
export function getServiceInventoryRecord(data) {
  return request({
    url: 'order-web-api/admin/service/inventory/log/pageBoxList',
    method: 'post',
    data,
  })
}
export function removeInventory(data) {
  return request({
    url: 'order-web-api/admin/service/inventory/removeInventory',
    method: 'post',
    data,
  })
}
export function getNexBaseProductType(data) {
  return request({
    url: 'order-web-api/admin/orderservice/getNextBaseServiceType',
    method: 'post',
    data,
  })
}

export function getSelectStoreUserList(data) {
  return request({
    url: 'supplier-web-api/api/store/user/getStoreUserList',
    method: 'post',
    data,
  })
}
